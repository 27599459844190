@define-mixin text-overflow {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@define-mixin text-overflowed $max-width: 100% {
    @mixin text-overflow;

    max-width: $max-width;
}
