$firstDelay: 2.5s;
$showTime: 1.2s;
$hideTime: 1.5s;
$letterTime: 1.5s;
$secondDelay: calc($firstDelay + $showTime * 5);

.animationWrap {
    position: absolute;
    font-family: Bodega-Script, sans-serif;
    bottom: 48px;
    left: calc(50% - 157px);
    height: 140px;
    width: 314px;
    color: white;
    line-height: initial;
}

.hidden {
    visibility: hidden;
}

.heart {
    width: 72px;
    height: 72px;
    position: absolute;
    bottom: 21px;
    left: 150px;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

.heartAnimate {
    stroke-dasharray: 1160;
    stroke-dashoffset: 1160;
    animation: draw $letterTime ease-out forwards calc($secondDelay + 500ms);
}

@keyframes textMain {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes showText {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.7;
    }
}

@keyframes hideText {
    from {
        opacity: 0.7;
    }

    to {
        opacity: 0;
    }
}

.textMain {
    position: absolute;
    bottom: 82px;
    font-size: 32px;
    left: 0;
    animation: textMain 1000ms ease-out both 1s;
}

.text1 {
    position: absolute;
    bottom: 37px;
    left: 3px;
    font-size: 27px;
    animation: showText $showTime ease-out both $firstDelay, hideText $hideTime ease-out forwards $secondDelay;
}

.text2 {
    position: absolute;
    bottom: 54px;
    left: 86px;
    font-size: 30px;
    animation: showText $showTime ease-out both calc($firstDelay + $showTime),
        hideText $hideTime ease-out forwards $secondDelay;
}

.text3 {
    position: absolute;
    bottom: 23px;
    left: 169px;
    font-size: 27px;
    animation: showText $showTime ease-out both calc($firstDelay + $showTime * 2),
        hideText $hideTime ease-out forwards $secondDelay;
}

.text4 {
    position: absolute;
    bottom: 0;
    left: 34px;
    font-size: 36px;
    animation: showText $showTime ease-out both calc($firstDelay + $showTime * 4),
        hideText $hideTime ease-out forwards $secondDelay;
}

.text5 {
    position: absolute;
    bottom: 40px;
    left: 186px;
    font-size: 36px;
    animation: showText $showTime ease-out both calc($firstDelay + $showTime * 3),
        hideText $hideTime ease-out forwards $secondDelay;
}

@keyframes letter1 {
    to {
        bottom: 20px;
        left: 105px;
        font-size: 48px;
        opacity: 1;
    }
}

@keyframes letter2 {
    to {
        bottom: 20px;
        left: 171px;
        font-size: 48px;
        opacity: 1;
    }
}

@keyframes letter3 {
    to {
        bottom: 20px;
        left: 120px;
        font-size: 48px;
        opacity: 1;
    }
}

@keyframes letter4 {
    to {
        bottom: 20px;
        left: 137px;
        font-size: 48px;
        opacity: 1;
    }
}

@keyframes letter5 {
    to {
        bottom: 20px;
        left: 154px;
        font-size: 48px;
        opacity: 1;
    }
}

.letter1 {
    position: absolute;
    bottom: 37px;
    left: 38px;
    font-size: 27px;
    animation: showText $showTime ease-out both $firstDelay, letter1 $letterTime ease forwards $secondDelay;
}

.letter2 {
    position: absolute;
    bottom: 54px;
    left: 154px;
    font-size: 30px;
    animation: showText $showTime ease-out both calc($firstDelay + $showTime),
        letter2 $letterTime ease forwards $secondDelay;
}

.letter3 {
    position: absolute;
    bottom: 23px;
    left: 274px;
    font-size: 27px;
    animation: showText $showTime ease-out both calc($firstDelay + $showTime * 2),
        letter3 $letterTime ease forwards $secondDelay;
}

.letter4 {
    position: absolute;
    bottom: 0;
    left: 71px;
    width: 35px;
    z-index: 10;
    font-size: 36px;
    animation: showText $showTime ease-out both calc($firstDelay + $showTime * 4),
        letter4 $letterTime ease forwards $secondDelay;
}

.letter5 {
    position: absolute;
    bottom: 40px;
    left: 247px;
    font-size: 36px;
    animation: showText $showTime ease-out both calc($firstDelay + $showTime * 3),
        letter5 $letterTime ease forwards $secondDelay;
}
