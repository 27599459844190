@font-face {
    font-family: Bodega-Script;
    src: url('assets/fonts/Bodega-Script.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
