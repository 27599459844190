.adminFeedbackPage {
    padding: 20px;
    padding-top: 5px;
}

.itemWrap {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.itemLabel {
    font-weight: 500;
    font-size: 18px;
}

.subLabel {
    font-weight: 500;
}

.itemComment {
    line-height: 24px;
}

.empty {
    color: var(--gray-dark);
}
