.hoursTable {
    display: flex;
    row-gap: 10px;
    column-gap: 3px;
    flex-wrap: wrap;
}

.hoursTableSmall {
    display: flex;
    row-gap: 10px;
    column-gap: 3px;
    flex-wrap: wrap;
    max-width: calc(100% - 95px);
}

@media (min-width: 367px) {
    .hoursTable {
        column-gap: 5px;
    }
}

@media (min-width: 373px) {
    .hoursTable {
        column-gap: 7px;
    }
}

@media (min-width: 382px) {
    .hoursTable {
        column-gap: 10px;
    }
}

@media (min-width: 379px) {
    .hoursTableSmall {
        column-gap: 5px;
    }
}

@media (min-width: 383px) {
    .hoursTableSmall {
        column-gap: 7px;
    }
}

@media (min-width: 389px) {
    .hoursTableSmall {
        column-gap: 10px;
    }
}

.hourItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 78px;
    height: 46px;
}

.booked {
    outline-width: 2px;
    outline-style: solid;
}

.settings {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 46px;
}

.timeFrom {
    line-height: 14px;
    margin-left: -28px;
}

.timeTo {
    line-height: 14px;
    margin-left: 28px;
}
