.bookingPage {
    padding: 20px;
    padding-top: 5px;
}

.header {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -1px;
}

.desc {
    font-size: 16px;
    line-height: 24px;
}
