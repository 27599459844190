.airbrushPage {
    padding: 20px;
    padding-top: 5px;
}

.header {
    font-size: 22px;
    font-weight: 500;
}

.text {
    font-size: 16px;
    line-height: 24px;
}

.icon {
    width: 50px;
    height: 50px;
}

.iconHeader {
    font-size: 16px;
    font-weight: 500;
}

.content {
    display: flex;
    align-items: center;
    gap: 15px;
}

.strong {
    font-weight: 500;
}

.imp {
    font-weight: 500;
    font-style: italic;
}
