.commentHeader {
    font-size: 18px;
    font-weight: 500;
}

.commentText {
    font-size: 16px;
    line-height: 20px;
    position: relative;
}

/* .commentText::before {
    content: '«';
    position: absolute;
    font-size: 20px;
    line-height: 16px;
    left: -11px;
    top: 0;
}

.commentText::after {
    content: '»';
    position: absolute;
    font-size: 20px;
    line-height: 16px;
    right: -11px;
    bottom: 0;
} */
