.main {
    display: flex;
    justify-content: center;
    min-height: 100%;
    background-color: var(--gray-light);
}

.body {
    width: 100%;
    position: relative;
    padding-top: var(--head-height);
    height: 100%;
}

.content {
    width: 100%;
    min-width: 350px;
    max-width: 500px;
    background-color: var(--white);
}

.alertWrap {
    position: fixed;
    margin-top: 80px;
    width: 100%;
    max-width: inherit;
    z-index: 20;
}

.alert {
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
