.day {
    display: flex;
    gap: 5px;
}

.name {
    width: 90px;
    font-size: 13px;
}

.disclaimer {
    color: #aaa;
}
