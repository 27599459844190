.title {
    flex-grow: 1;
}

.link {
    text-decoration: none;
    width: 100%;
    font-size: 18px;
}

.menuList {
    text-align: center;
    position: absolute;
    top: var(--head-height);
    width: 100%;
    right: 0;
}

.logo {
    background-image: url('front/assets/images/logo.png');
    background-repeat: no-repeat;
    background-size: 100px;
    width: 100px;
    height: 46px;
}

.socials {
    padding: 6px 16px;
    box-sizing: content-box;
    height: 40px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.socLogo {
    background-repeat: no-repeat;
    background-size: 40px;
    width: 40px;
}

.inst {
    background-image: url('front/assets/images/instagram_bw.png');
}

.teleg {
    background-image: url('front/assets/images/telegram_bw.png');
}

.vk {
    background-image: url('front/assets/images/vk_bw.png');
}

.admin {
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: 0;
    left: 0;
    z-index: 1;
}
