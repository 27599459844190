@import url('./fonts.css');
@import url('./vars.css');

html,
body {
    height: 100%;
}

:global #root {
    height: 100%;
}

ul {
    margin: 0;
    padding: 0;
}
