.imagePage {
    padding: 20px;
    padding-top: 5px;
}

.video {
    width: 100%;
    object-fit: cover;
    display: block;
}

.header {
    font-size: 22px;
    font-weight: 500;
}

.text {
    font-size: 16px;
    line-height: 22px;
}
