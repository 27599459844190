.portfolioPage {
    padding: 20px;
    padding-top: 5px;
}

.header {
    font-size: 23px;
    font-style: italic;
    padding-bottom: 10px;
}

.name {
    font-size: 18px;
    font-style: italic;
}

.galery {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.photo {
    width: calc(50% - 5px);
    object-fit: cover;
    cursor: pointer;
}
