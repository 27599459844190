.mainPage {
    padding: 20px;
    padding-top: 5px;
}

.section {
    position: relative;
}

.galery {
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
}

.textBackground {
    position: absolute;
    background: linear-gradient(to bottom, #fff0, #0007);
    bottom: 0;
    left: 0;
    height: 60%;
    width: 100%;
}

.header {
    font-size: 22px;
    font-weight: 500;
}

.sectionText {
    font-size: 16px;
    line-height: 22px;
}

.imageForeground,
.imageForegroundLow {
    position: absolute;
    background: linear-gradient(to bottom, #fff0, #0007);
    bottom: 0;
    left: 0;
    height: 60%;
    width: 100%;
}

.imageForegroundLow {
    height: 50%;
    background: linear-gradient(to bottom, #fff0, #0008);
}

.imageButton {
    position: absolute;
    bottom: 20px;
    width: 200px;
    left: calc(50% - 100px);
}

.imageButtonAbout {
    position: absolute;
    bottom: 20px;
    width: 60%;
    right: 10%;
}

.rogov {
    position: absolute;
    width: 15%;
    z-index: 100;
    bottom: 20px;
    left: 8%;
    opacity: 0.8;
}

.iconsGalery {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    /* position: absolute;
    bottom: 70px;
    left: 10px;
    color: var(--white); */
}

.iconWrap {
    width: calc(33.3% - 4px);
    display: flex;
    gap: 3px;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--gray-dark);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    background-color: #fff3;
}

.icon {
    width: 50px;
    height: 50px;
}

.iconText {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
}
