.header {
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 10px;
}

.header:first-child {
    margin-top: 0;
}

.accordionItem {
    padding-left: 14px;
    padding-right: 5px;
}

.accordionName {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.answer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    line-height: 24px;
}

.list {
    list-style: disc;
    margin-left: 16px;
}

.strong {
    font-weight: 500;
}
