.adminTrainingPage {
    padding: 20px;
    padding-top: 5px;
}

.itemLabel {
    font-weight: 500;
    font-size: 18px;
}

.subLabel {
    font-weight: 500;
}

.itemComment {
    @mixin text-overflow;
}

.empty {
    color: var(--gray-dark);
}
