.header {
    font-size: 24px;
}

.saveBtn {
    width: 110px;
}

.error {
    color: var(--red);
    line-height: 36px;
    font-size: 18px;
}
