.imageWrap {
    position: relative;
    opacity: 0;
    transition: opacity 400ms ease;
}

.photo {
    width: 100%;
    object-fit: cover;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.loaded {
    opacity: 1;
}
