.phone :global(.MuiInputBase-input) {
    font-family: Consolas, monospace;
    font-size: 17px;
}

.boxesHeader {
    font-size: 16px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
}

.footer {
    display: flex;
    align-items: center;
}

.deleteBtn {
    padding: 0;
    width: 45px;
    min-width: 45px;
    height: 34px;
    border-radius: 4px;
}

.select {
    font-size: 15px;
    min-height: 46px;
}

.select:global(.MuiMenuItem-root),
.select:global(.MuiMenuItem-root:hover),
.select:global(.MuiMenuItem-root.Mui-disabled) {
    background-color: transparent;
}

.selectBtn {
    width: 100px;
    margin: 8px;
}

.selectFooter {
    width: 100%;
    display: flex;
    justify-content: center;
}

.price {
    font-size: 15px;
}
