.adminPortfolioPage {
    padding: 20px;
    padding-top: 5px;
}

.itemWrap {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.itemLabel {
    font-weight: 500;
}

.empty {
    color: var(--gray-dark);
}
