.sliderWrap {
    position: relative;
}

.contentWrap {
    transition: height 600ms ease;
    width: calc(100% - 60px);
    overflow: hidden;
}

.buttonLeftWrap {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
}

.buttonRightWrap {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
}

.buttonLeft,
.buttonRight {
    top: calc(50% - 15px);
    position: absolute;
    width: 22px;
    min-width: 22px;
    height: 22px;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
}

.buttonLeft {
    left: 0;
}

.buttonRight {
    right: 0;
}

.left,
.right {
    width: 18px;
    height: 18px;
}

.dots {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    border: 1px solid var(--gray-dark);
    margin: 0 4px;
    transition: background-color 200ms ease;
}

.activeDot {
    background-color: var(--gray-dark);
}
