.header {
    padding: 4px;
    font-size: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;
    letter-spacing: -1px;
}

.bakcBtn {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.mainText {
    line-height: 30px;
}

.subText {
    font-size: 18px;
    line-height: 20px;
    color: var(--gray-dark);
}
