:root {
    --red: #fc2c39;
    --green: #04be3a;
    --white: #fff;
    --black: #333;
    --gray-light: #fafafa;
    --gray-dark: #888;
    --gray-darkest: #666;
    --head-height: 64px;
    --main-font: 'Montserrat', sans-serif !important;
}
