.image {
    width: 100%;
}

.slider {
    position: relative;
    width: 100%;
}

.buttonLeftWrap {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
}

.buttonRightWrap {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
}

.buttonLeft,
.buttonRight {
    opacity: 1;
    transition: opacity 300ms ease;
    position: absolute;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.hidden {
    opacity: 0;
    transition: opacity 1s ease;
}

.buttonLeft {
    top: calc(50% - 15px);
    left: 5px;
}

.buttonRight {
    top: calc(50% - 15px);
    right: 5px;
}

.dots {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    border: 1px solid var(--gray-dark);
    margin: 0 4px;
    transition: background-color 200ms ease;
}

.activeDot {
    background-color: var(--gray-dark);
}
