.adminPage {
    padding: 20px;
    padding-top: 5px;
}

.buttonsWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.button {
    width: calc(50% - 5px);
    padding: 6px;
}

.header {
    font-size: 22px;
    font-weight: 500;
}
