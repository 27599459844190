.contactsWrap {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.phone {
    font-size: 20px;
    color: var(--black);
    text-decoration: none;
}

.locWrap {
    display: flex;
    line-height: 24px;
    text-decoration: none;
    color: var(--black);
    gap: 8px;
}

.addressWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.metro {
    background-image: url('front/assets/images/metro.png');
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 25px;
}

.socials {
    height: 40px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.socLogo {
    background-repeat: no-repeat;
    background-size: 40px;
    width: 40px;
}

.inst {
    background-image: url('front/assets/images/instagram_bw.png');
}

.teleg {
    background-image: url('front/assets/images/telegram_bw.png');
}

.wa {
    background-image: url('front/assets/images/wa_bw.png');
}

.vk {
    background-image: url('front/assets/images/vk_bw.png');
}
