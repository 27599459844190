.checkBtn {
    height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;
}

.phonePopup {
    padding: 20px;
}

.disclaimer {
    line-height: 46px;
    display: flex;
    justify-content: center;
    color: #aaa;
}
