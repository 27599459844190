.file {
    position: relative;
}

.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputLabel {
    width: 80px;
    height: 80px;
    border: 1px solid var(--gray-dark);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.images {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.imageWrap {
    position: relative;
}

.image {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    display: block;
    transition: box-shadow 200ms ease, transform 200ms ease;
}

.draggingImage {
    transform: scale(1.2);
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), -1px 0 15px 0 rgb(34 33 81 / 1%), 0 15px 15px 0 rgb(34 33 81 / 25%);
}

.deleteImage,
.shareImage,
.dragBtn {
    position: absolute;
    padding: 2px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: rgb(0 0 0 / 20%);
    color: white;
    cursor: pointer;
}

.deleteImage {
    bottom: 2px;
    right: 2px;
}

.shareImage {
    bottom: 2px;
    left: 2px;
}

.dragBtn {
    top: 2px;
    right: 2px;
}

.buttonsWrap {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 0.3s, opacity 0.3s ease;
}
