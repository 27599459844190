.tabPanel {
    padding: 0;
    line-height: 24px;
}

.list {
    list-style: disc;
    margin-left: 16px;
    margin-top: 8px;
    font-size: 14px;
}

.tabList {
    min-height: initial;
}

.tab {
    min-height: initial;
    padding: 12px;
}

.accordionItem {
    padding-left: 14px;
    padding-right: 5px;
}

.accordionContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.accordionTop {
    display: flex;
    justify-content: space-between;
}

.accordionName {
    font-weight: 500;
    letter-spacing: -0.4px;
}

.accordionPrice {
    color: var(--gray-dark);
    margin-left: 8px;
}

.accordionLevel2 {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    color: var(--gray-darkest);
    letter-spacing: -0.6px;
}

.accordionLevel3 {
    font-size: 11px;
    color: var(--gray-dark);
    line-height: 14px;
    font-style: italic;
}
