.trainingPage {
    padding: 20px;
    padding-top: 5px;
}

.header {
    font-size: 22px;
    font-weight: 500;
}

.video {
    width: 100%;
    object-fit: cover;
    display: block;
}

.text {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.label {
    font-size: 16px;
    font-weight: 500;
}

.list {
    list-style: disc;
    margin-left: 16px;
    font-size: 14px;
}

.hidden {
    visibility: hidden;
}
