.adminPricePage {
    padding: 20px;
    padding-top: 5px;
    height: 100%;
}

.text {
    resize: none;
    width: 100%;
    height: 100%;
    overflow: scroll;
    white-space: pre;
    border: none;
    outline: 1px solid var(--gray-dark);
}

.error {
    color: var(--red);
    line-height: 16px;
    font-size: 16px;
}

.success {
    color: var(--green);
    line-height: 16px;
    font-size: 16px;
}

.footer {
    height: 20px;
}
