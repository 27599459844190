.browsPage {
    padding: 20px;
    padding-top: 5px;
}

.header {
    font-size: 22px;
    font-weight: 500;
}

.imageForegroundLow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(to bottom, #fff0, #0008);
}

.imageButton {
    position: absolute;
    bottom: 20px;
    width: 200px;
    left: calc(50% - 100px);
}

.label {
    font-size: 16px;
    font-weight: 500;
}

.text {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.galery {
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto;
}

.list {
    list-style: disc;
    margin-left: 16px;
    font-size: 14px;
}

.strong {
    font-weight: 500;
}

.hidden {
    visibility: hidden;
}
